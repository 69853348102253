<template>
  <ToolsFormEdgeContact />
</template>

<script>
import ToolsFormEdgeContact from '../../components/tools/ToolsFormEdgeContact.vue';

export default {
  components: {
    ToolsFormEdgeContact,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
